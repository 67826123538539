import { useTranslation } from 'react-i18next';

import { Header } from '../../components/navigation/header';
import { PageLogo } from '../../components/utils/pageLogo';

export default function SuspendedPage({ userStatus }) {
	const { t } = useTranslation();

	const suspendedText = 'Your account has been closed due to fraud department';
	const restrictedCountryText = 'Your account has been closed due to restricted country';
	const terminatedText = 'Your account has been terminated due to your request, in the next 30 days this account will be completly deleted.';

	const selectedText = userStatus === 'inactive' ? suspendedText : userStatus === 'restricted' ? restrictedCountryText : terminatedText;

	return (
		<div className="p-5 h-screen flex flex-col justify-between">
			<div>
				<Header title={t('pages.suspended.title')} />
			</div>
			<h1 className="text-xl text-center text-red uppercase font-bold" dir="rtl">
				{/* {t('pages.suspended.message')} */}
				{selectedText}
			</h1>

			<PageLogo className="opacity-[50%] mb-20" />
		</div>
	);
}
