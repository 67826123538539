import React, { useEffect, useState } from 'react';

import { CustomModal } from './customModal';

import { PrimaryButton } from '../buttons/primary-button';

import { SecondaryButton } from '../buttons/secondary-button';

import { useTranslation } from 'react-i18next';
import { getUserProfileAPI, getVerestroUserExists } from '../../services/user';
import { Input } from '../inputs/textInput';
import { CountriesListWithPrefix } from '../../utils/constants/countries-list';
import SelectInput from '../inputs/selectInput';
import Loader from '../../pages/utils/loader';
import { createVerestroUserAndVerifyKYC } from '../../services/cards';

export const AddressVerifyModal = ({ isOpen, onClose, onAction }) => {
	const { t } = useTranslation();

	const [isLoading, setIsLoading] = useState(false);

	const [errMsg, setErrMsg] = useState('');

	const [data, setData] = useState({
		// phoneNumber: '',
		// birthdate: '',
		street: '',
		city: '',
		country: '',
		number: '',
		postalCode: '',
		apartment: '',
	});

	useEffect(() => {
		const fetch = async () => {
			if (!isLoading) {
				setIsLoading(true);

				const hasVerestroUser = await getVerestroUserExists();

				if (hasVerestroUser.data) return setIsLoading(false);

				const response = await getUserProfileAPI();

				if (!response.isSuccess) return setIsLoading(false);

				const responseData = response.data;
				// const date = responseData.user.birthdate ? new Date(responseData.user.birthdate).toLocaleDateString('en-CA') : '';

				setData({
					// phoneNumber: responseData.user.phoneNumber,
					// birthdate: date,
					street: responseData.street,
					city: responseData.city,
					country: responseData.country,
					number: responseData.number,
					postalCode: responseData.postalCode,
					apartment: responseData.apartment,
				});
			}
			setIsLoading(false);
		};
		fetch();
	}, []);

	const confirm = async () => {
		if (!isLoading) {
			setIsLoading(true);
			const response = await createVerestroUserAndVerifyKYC(data);
			if (!response.isSuccess) {
				setIsLoading(false);
				return setErrMsg(response.error);
			}
		}

		setIsLoading(false);
		onClose();
		onAction();
	};

	return (
		<CustomModal
			isOpen={isOpen}
			onClose={onClose}
			title={'Please verify your address information'}
			subTitle={'In order to apply for a card or an IBAN your address required to written in english lanaugage, please verify your address.'}
			children={
				isLoading ? (
					<Loader />
				) : (
					<div>
						<div className="flex flex-col">
							{/* <p>Phone Number</p>
						<Input
							onChange={(value) => {
								setData((data) => ({ ...data, phoneNumber: value }));
							}}
							placeholder={t('inputs.placeholders.phoneNumber')}
							type="text"
							value={data.phoneNumber}
						/> */}

							{/* <p>Birthdate</p>
						<Input
							onChange={(value) => {
								setData((data) => ({ ...data, birthdate: value }));
							}}
							placeholder={t('inputs.placeholders.postalCode')}
							type="date"
							value={data.birthdate}
						/> */}
							<p>Country</p>
							{
								<SelectInput
									onChange={(value) => {
										setData((data) => ({
											...data,
											country: value,
										}));
									}}
									defaultValue={
										data.country
											? { value: data.country, label: CountriesListWithPrefix.find((country) => country.code === data.country).country }
											: ''
									}
									options={CountriesListWithPrefix.map((opt) => ({
										value: opt.code,
										label: opt.country,
									}))}
								/>
							}
							{/* <Input
								onChange={(value) => {
									setData((data) => ({ ...data, country: value }));
								}}
								placeholder={t('inputs.placeholders.country')}
								type="text"
								value={data.country}
							/> */}
							<p>City</p>
							<Input
								onChange={(value) => {
									setData((data) => ({ ...data, city: value }));
								}}
								placeholder={t('inputs.placeholders.city')}
								type="text"
								value={data.city}
							/>
							<p>Street</p>
							<Input
								onChange={(value) => {
									setData((data) => ({ ...data, street: value }));
								}}
								placeholder={t('inputs.placeholders.street')}
								type="text"
								value={data.street}
							/>
							<p>Street Number</p>
							<Input
								onChange={(value) => {
									setData((data) => ({ ...data, number: value }));
								}}
								placeholder={t('inputs.placeholders.streetNumber')}
								type="text"
								value={data.number}
							/>
							<p>Apartment</p>
							<Input
								onChange={(value) => {
									setData((data) => ({ ...data, apartment: value }));
								}}
								placeholder={t('inputs.placeholders.apartment')}
								type="text"
								value={data.apartment}
							/>

							<p>Postal Code</p>
							<Input
								onChange={(value) => {
									setData((data) => ({ ...data, postalCode: value }));
								}}
								placeholder={t('inputs.placeholders.postalCode')}
								type="text"
								value={data.postalCode}
							/>

							<PrimaryButton onClick={confirm} text={t('buttons.verify')} textColor="text-white" />
							<SecondaryButton
								onClick={() => {
									onClose();
								}}
								text={t('buttons.close')}
								textColor="text-black"
							/>
							<p className="text-red">{errMsg}</p>
						</div>
					</div>
				)
			}
		/>
	);
};
